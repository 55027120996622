<template>
  <div class="d-flex flex-column h-100">
    <Header style="height:160px;">
      <loader-bloc class="mt-3 mt-md-5 ml-4 mb-3 mb-md-5" v-if="loadingData && loadingComptes"></loader-bloc>
      <div
        v-if="!loadingData && !loadingComptes"
        class="w-100 d-flex justify-content-between align-items-center"
      >
        <div class="p-3 p-md-0">
          <h2 class="m-0">{{ client.client_nom }} {{ client.client_prenom }}</h2>
          <div v-if="client.client_comptes">{{ client.client_comptes.description }}</div>
          <div class="d-flex flex-column">
            <div class="mr-3" v-if="client.client_mail1">
              <a class="d-flex" :href="'mailto:' + client.client_mail1">
                <BaseIcon class name="mail" width="15" height="15"></BaseIcon>
                <span class="ml-1">{{ client.client_mail1 }}</span>
              </a>
            </div>
            <div class="mr-3" v-if="client.client_tel1">
              <a class="d-flex" :href="'tel:' + client.client_tel1">
                <BaseIcon class name="phone" width="15" height="15"></BaseIcon>
                <span class="ml-1">{{ client.client_tel1 }}</span>
              </a>
            </div>
          </div>
        </div>
        <close-link
          class="ml-auto mr-3"
          text="Retour à la liste des clients"
          link="/clients"
          v-if="!loadingData && !loadingComptes"
        ></close-link>
      </div>
    </Header>
    <div class="d-flex flex-column flex-md-row h-100">
      <menu-left style="min-width:190px;">
        <menu-left-item route="crm_client_infos" text="Informations" icon="info"></menu-left-item>
        <menu-left-item route="crm_client_coordonnees" text="Coordonnées" icon="phone"></menu-left-item>
        <menu-left-item route="crm_client_rdv" text="RDV" icon="calendar"></menu-left-item>
        <menu-left-item route="crm_client_suivi" text="Suivi" icon="compass"></menu-left-item>
        <menu-left-item route="crm_client_satisfaction" text="Satisfaction" icon="clipboard"></menu-left-item>
        <menu-left-item route="crm_client_users" text="Utilisateurs" icon="eye"></menu-left-item>
      </menu-left>
      <transition name="fade" mode="out-in">
        <router-view :data="data" v-if="!loadingData && !loadingComptes" class="pl-2 pr-2"></router-view>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/bases/Header.vue";
import MenuLeft from "@/components/bases/MenuLeft.vue";
import MenuLeftItem from "@/components/bases/MenuLeftItem.vue";
import LoaderBloc from "@/components/bases/LoaderBloc.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import CloseLink from "@/components/bases/CloseLink.vue";

export default {
  components: {
    Header,
    MenuLeft,
    MenuLeftItem,
    LoaderBloc,
    BaseIcon,
    CloseLink
  },
  props: {
    id: {
      Type: Number,
      required: true
    }
  },
  data() {
    return {
      loadingData: false,
      loadingComptes: false
    };
  },
  methods: {
    ...mapActions({
      getClient: "clients/getClient",
      getData: "clients/getData"
      //loadSuivi: "clients/loadSuivi",
      //loadSatisfaction: "clients/loadSatisfaction"
    })
  },
  computed: {
    ...mapGetters({
      client: "clients/client",
      data: "clients/data"
    }),
    ...mapFields("clients", [
      "client.client_suivis",
      "client.client_satisfactions"
    ]),
    color: function() {
      return this.$store.getters["colors/colors"].color2;
    }
  },
  watch: {
    "$route.params.id": function() {
      this.loadingComptes = true;
      this.getClient(this.id).then(() => {
        //this.loadSuivi(this.client_suivis);
        //this.loadSatisfaction(this.client_satisfactions);
        this.loadingComptes = false;
      });
    }
  },
  mounted() {
    this.loadingData = true;
    this.loadingComptes = true;
    this.getData().then(() => (this.loadingData = false));
    this.getClient(this.id).then(() => {
      //this.loadSuivi(this.client_suivis);
      //this.loadSatisfaction(this.client_satisfactions);
      this.loadingComptes = false;
    });
  }
};
</script>
